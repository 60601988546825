import ProjectHeroPanel from "../ProjectHeroPanel/ProjectHeroPanel";
import { motion, useScroll, useTransform } from 'framer-motion';
import ScrollToTopButton from "../ScrollToTopButton";
import ImageSwiper from "../ImageSwiper";
import ImageHorizontalScroll from "../ImageHorizontalScroll";
import "../components.css";
import { Link } from "react-router-dom";
import React, { useRef } from 'react';
import ImageStack from "../Imagestack";
import ImageSlideLRM from "../ImageSlideLRM";

//AB testing image imports
const abtest1 =  require(`../Images/previsit/checkin-abtest-1.png`);
const abtest2 =  require(`../Images/previsit/checkin-abtest-2.png`);


const interactionValidation = [
    { src: require(`../Images/previsit/validating-interation-pattern.png`), alt: 'Image 1' },
    { src: require(`../Images/previsit/validating-taparea.png`), alt: 'Image 2' },
    { src: require(`../Images/previsit/validating-touch-targets.png`), alt: 'Image 3' },
];



const componentGroup = [
    { src: require(`../Images/optum/text-inputs-1.png`), alt: 'Image 1' },
    { src: require(`../Images/optum/multi-inputs-1.png`), alt: 'Image 2' },
    { src: require(`../Images/optum/multi-inputs-2.png`), alt: 'Image 3' },
    { src: require(`../Images/optum/toggle.png`), alt: 'Image 3' },
    { src: require(`../Images/optum/other-1.png`), alt: 'Image 3' },
    { src: require(`../Images/optum/other-2.png`), alt: 'Image 3' },
    { src: require(`../Images/optum/other-3.png`), alt: 'Image 3' },
];


const stackImages = [
    {src: require(`../Images/previsit/test1.png`)},
    {src: require(`../Images/previsit/test2.png`)},
    {src: require(`../Images/previsit/test3.png`)},
    {src: require(`../Images/previsit/test4.png`)},
    {src: require(`../Images/previsit/test5.png`)},
    {src: require(`../Images/previsit/test6.png`)},
    {src: require(`../Images/previsit/test7.png`)},
    {src: require(`../Images/previsit/test8.png`)},
];


function Previsit(){
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
            target: targetRef,
        });
    
        // Define motion values for transformations
        const opacity = useTransform(scrollYProgress, [0.5, 1], [1, 0]);
    return(
        <>
            <ScrollToTopButton/>
            <ProjectHeroPanel
                image="previsit-banner.png"
                text1="Designed Conversational AI for senior."
                text2="Orchestrated design sprints to optimize usability, reducing user drop-off."
                text3="Delivered functional design artifacts and prototypes for usability testing."
                text4="Clinical team"
                text5="Optum Research Group"
                text6=" Sr UX Designer, ML Engineering Lead, Clinical Team"
                text7="Nov 2024 - Present"
                text8=""
                text9=""
            >
            </ProjectHeroPanel>

            {/* ----------------------------------------------Overview Section of the project----------------------------------------- */}
            <div className="block relative mt-8 mb-12 px-4 sm:px-6 lg:px-8">
                <div className="container mx-auto max-w-7xl">
                    <h6 className="font-mono text-sm md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500">
                        INTRODUCTION
                    </h6>
                    <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold tracking-tight mb-4 text-gray-900 dark:text-gray-100 leading-tight">
                        Optum - Pre-Visit Assessment
                    </h1>
                    <h4 className="text-xl md:text-2xl font-semibold mb-2 mt-8 text-gray-600 dark:text-gray-200">
                        About Pre-Visit Assessment
                    </h4>
                    <p className="text-base md:text-lg mb-8 text-gray-600 dark:text-gray-400">
                        Assessment includes a thorough review of the member's health history,
                        which usually takes about 10 minutes. To save time, members can now complete this health assessment digitally before the visit.
                        This allows the healthcare provider to focus more on addressing the member's specific questions and needs during the in-person visit.
                    </p>
                    <h4 class="text-xl md:text-2xl font-semibold mb-2 mt-8  text-gray-600 dark:text-gray-200 ">
                    Impact
                    </h4>
                    <div class="flex flex-col p-8 items-start w-full relative rounded-xl bg-gradient-to-r from-indigo-500 to-blue-500">
                        <h6 class="font-mono text-lg md:text-base font-semibold mb-2 tracking-widest text-white dark:text-gray-200">
                            FACTSHEET
                        </h6>
                        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-x-8 w-full">
                            <div class="text-center md:text-left">
                                <h2 class="text-3xl md:text-4xl font-semibold text-white leading-snug mb-2">40%</h2>
                                <p class="text-lg md:text-base text-white leading-relaxed">Reduction in telephonic calls measured by monthly call volume</p>
                            </div>
                            <div class="text-center md:text-left">
                                <h2 class="text-3xl md:text-4xl font-semibold text-white leading-snug mb-2">400K</h2>
                                <p class="text-lg md:text-base text-white leading-relaxed">Estimated users to be impacted</p>
                            </div>
                            <div class="text-center md:text-left">
                                <h2 class="text-3xl md:text-4xl font-semibold text-white leading-snug mb-2">WCAG 2.2</h2>
                                <p class="text-lg md:text-base text-white leading-relaxed">Compliant for seniors</p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-0 mt-16">
                        <div className="flex justify-center items-center">
                            <img src={require(`../Images/previsit/intro-1.png`)} alt="Mobile Prototype" className="w-72 lg:-mr-32 h-auto max-w-xs md:max-w-sm lg:max-w-md shadow-2xl shadow-slate-400 dark:shadow-blue-950 rounded-3xl" />
                        </div>
                        <div className="p-8 pl-0 flex flex-col justify-center">
                            <h4 className="text-xl md:text-2xl font-semibold mb-2 mt-8 text-gray-600 dark:text-gray-200">
                                Problem statement
                            </h4>
                            <p className="text-lg md:text-base mb-8 text-gray-600 dark:text-gray-400">
                                The current telephonic outreach for pre-visit assessment is resource intensive, 
                                digital assessment have high drop-off rates among seniors.
                            </p>

                            <h4 className="text-xl md:text-2xl font-semibold mb-2 mt-8 text-gray-600 dark:text-gray-200">
                                Solution
                            </h4>
                            <p className="text-lg md:text-base mb-8 text-gray-600 dark:text-gray-400">
                                Digital Pre-Visit assessment with AI companion to reduce telephonic outreach and improve user experience and reduce user drop-off rates.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container mx-auto px-4 mt-24">
                    <h6 className="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500">
                        CONTRIBUTIONS
                    </h6>
                    <h3 className="text-2xl md:text-3xl font-extrabold  text-gray-700 dark:text-gray-200">
                        Design and Research Contributions
                    </h3>
                    <h4 className="text-xl md:text-2xl font-semibold mb-2 mt-8 text-gray-600 dark:text-gray-200">
                        Streamlining Healthcare Onboarding: From Phone to Digital Assessments
                    </h4>
                   
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                        <div className="flex flex-col items-start w-full mt-4 p-6 relative rounded-xl border border-indigo-800 bg-stone-300/80 dark:bg-gray-800">
                            <p className="text-base md:text-lg font-semibold mb-2 text-gray-600 dark:text-gray-200 leading-relaxed">Designed AI Companion for seniors</p>
                        </div>
                        <div className="flex flex-col items-start w-full mt-4 p-6 relative rounded-xl border border-indigo-800 bg-stone-300/80 dark:bg-gray-800">
                            <p className="text-base md:text-lg font-semibold mb-2 text-gray-600 dark:text-gray-200 leading-relaxed"> Usability optimization for seniors</p>
                        </div>
                        <div className="flex flex-col items-start w-full mt-4 p-6 relative rounded-xl border border-indigo-800 bg-stone-300/80 dark:bg-gray-800">
                            <p className="text-base md:text-lg font-semibold mb-2 text-gray-600 dark:text-gray-200 leading-relaxed">Features to fix dropout rate</p>
                        </div>
                        <div className="flex flex-col items-start w-full mt-4 p-6 relative rounded-xl border border-indigo-800 bg-stone-300/80 dark:bg-gray-800">
                            <p className="text-base md:text-lg font-semibold mb-2 text-gray-600 dark:text-gray-200 leading-relaxed">Planning and executing usability testing for Seniors</p>
                        </div> 
                    </div>
                    <hr className="mt-8 mb-16 border-slate-800" />
                 </div>   

            </div>      

            {/*------------------------Inciting Incident----------------------------  */}

            <div className="relative block mt-24 mb-12">
                <div className="container mx-auto px-4">
                    {/* <h4 className="text-xl md:text-2xl font-semibold -mb-24 mt-8 text-gray-600 dark:text-gray-200">
                        Storyboard users interacting with Nurse Practitioners
                    </h4>
                    <ImageHorizontalScroll image={houseCallsIntroImages}/> */}
                    <h6 className="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500">
                        USER RESEARCH FOR EVALUATION OF AI AGENTS IN WORKFLOWS
                    </h6>
                    <h3 className="text-2xl md:text-3xl font-extrabold  text-gray-700 dark:text-gray-200">
                        Research methods
                    </h3>
                    <h4 className="text-xl md:text-2xl font-semibold mb-2 mt-8 text-gray-600 dark:text-gray-200">
                        Business expectations
                    </h4>
                    <p className="text-base md:text-lg mb-4 text-gray-600 dark:text-gray-400">
                        With <span className="text-white">30k+ pre-visit assessments</span> completed each month, telephonic outreach is
                        also resource intensive. Therefore, a AI-enabled digital assessment is being introduced.
                    </p>
                </div>                
                
                
                <div className="container mx-auto px-4 relative flex flex-col md:flex-row items-center" ref={targetRef}>
                     <h4 className="text-xl md:text-2xl font-semibold mt-8 text-gray-600 dark:text-gray-200">
                        Journey map: Hypothesis first approach.
                    </h4>
                    
                    <div class="flex flex-col rounded-xl p-8 mt-4 mb-4 bg-stone-300/80 dark:bg-gray-800">
                        
                       
                        <p className="text-base md:text-lg  text-gray-600 dark:text-gray-200 ">
                            If we incorporate AI agents as assessment companions, then we expect to see a significant reduction in telephonic outreach,
                            because of the widespread adoption of AI agents in various workflows and the general population's increasing familiarity with automated assistants
                        </p>

                    </div>
                 
                    <img 
                        src={require(`../Images/previsit/flowdgm-journey-map.png`)} 
                        className="w-full lg:mb-4 md:w-auto z-0 mb-4 md:mb-0 md:mr-4 rounded-xl" 
                        alt=""
                    />
                    <img 
                        src={require(`../Images/previsit/journey-map.png`)} 
                        className="w-full md:w-auto z-0 mb-4 md:mb-0 md:mr-4 rounded-xl" 
                        alt=""
                    />

                    {/* ----------------------Plot point 1----------------------- */}
                    <div class="flex flex-col rounded-xl p-8 mt-8 bg-gradient-to-r from-indigo-500 to-blue-500">
                        <h4 className="text-xl md:text-2xl font-semibold mb-2  text-gray-100 dark:text-gray-200">
                            Design direction
                        </h4>
                        
                        <p className="text-base md:text-lg  text-gray-100 dark:text-gray-200 ">
                            If we incorporate AI agents as assessment companions, then we expect to see a significant reduction in telephonic outreach,
                            because of the widespread adoption of AI agents in various workflows and the general population's increasing familiarity with automated assistants
                        </p>
                    </div>
                </div>
            </div>


            {/* ----------------Interface explorations----------------------- */}
            <div className="relative block mt-24 mb-12">
                <div className="container mx-auto px-4">
                    <h6 className="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500">
                        INTERFACE DESIGN
                    </h6>
                    <h3 className="text-2xl md:text-3xl font-extrabold text-gray-700 dark:text-gray-200">
                        Explorations
                    </h3>
                    
                </div>   
                
                <div className="container mx-auto px-4">
                    <h4 className="text-xl md:text-2xl font-semibold mb-2 mt-2 text-gray-600 dark:text-gray-200">
                        Interface considerations for seniors
                    </h4>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                            <div className="flex flex-col items-start w-full mt-4 p-6 relative rounded-xl border border-indigo-800 bg-stone-300/80 dark:bg-gray-800">
                                <p className="text-base md:text-lg font-semibold mb-2 text-gray-600 dark:text-gray-200 leading-relaxed">Design for familiarity</p>
                            </div>
                            <div className="flex flex-col items-start w-full mt-4 p-6 relative rounded-xl border border-indigo-800 bg-stone-300/80 dark:bg-gray-800">
                                <p className="text-base md:text-lg font-semibold mb-2 text-gray-600 dark:text-gray-200 leading-relaxed">Maintain low cognitive load</p>
                            </div>
                            <div className="flex flex-col items-start w-full mt-4 p-6 relative rounded-xl border border-indigo-800 bg-stone-300/80 dark:bg-gray-800">
                                <p className="text-base md:text-lg font-semibold mb-2 text-gray-600 dark:text-gray-200 leading-relaxed">Context clarity for seniors</p>
                            </div>
                            <div className="flex flex-col items-start w-full mt-4 p-6 relative rounded-xl border border-indigo-800 bg-stone-300/80 dark:bg-gray-800">
                                <p className="text-base md:text-lg font-semibold mb-2 text-gray-600 dark:text-gray-200 leading-relaxed">Fallback plan for high risk situations</p>
                            </div> 
                    </div>
                    <div className="mt-4">
                        <img 
                            src={require(`../Images/previsit/previsit-context-sense1.png`)} 
                            className="w-full lg:mb-4 md:w-auto z-0 mb-4 md:mb-0 md:mr-4 rounded-xl" 
                            alt=""
                        />
                        <img 
                            src={require(`../Images/previsit/previsit-context-sense2.png`)} 
                            className="w-full lg:mb-4 md:w-auto z-0 mb-4 md:mb-0 md:mr-4 rounded-xl" 
                            alt=""
                        />
                        <img 
                            src={require(`../Images/previsit/previsit-context-sense3.png`)} 
                            className="w-full lg:mb-4 md:w-auto z-0 mb-4 md:mb-0 md:mr-4 rounded-xl" 
                            alt=""
                        />
                         <img 
                            src={require(`../Images/previsit/previsit-context-sense5.png`)} 
                            className="w-full lg:mb-4 md:w-auto z-0 mb-4 md:mb-0 md:mr-4 rounded-xl" 
                            alt=""
                        />
                                         
                    </div>
                    <div class="flex flex-col rounded-xl p-8 mt-8 bg-gradient-to-r from-indigo-500 to-indigo-600">
                        <h4 className="text-xl md:text-2xl font-semibold mb-2  text-gray-100 dark:text-gray-200">
                            Evaluation question
                        </h4>
                        
                        <p className="text-base md:text-lg  text-gray-100 dark:text-gray-200 ">
                            Do we need a companion for assessment, would we resolve for drop-out rates and satisfy usability standards for the elderly?
                        </p>
                    </div>


                </div>

                
                {/* <ImageStack image={stackImages}/> */}
                
                {/* <ImageSlideLRM image1={abtest1} image2={abtest2} text="A/B Testing yielded high sign-up metrics, with factors like inforgraphic and info messages"/>
                <div className="container mx-auto px-4 relative flex flex-col md:flex-row items-center" ref={targetRef}>
                    <img 
                        src={require(`../Images/previsit/prototype-flows.png`)} 
                        className="w-full md:w-auto scale-110 z-0 mb-4 md:mb-0 md:mr-4" 
                        alt=""
                    />
                    <motion.h3 
                        style={{opacity}} 
                        className="absolute top-0 right-0 text-xl md:text-2xl lg:text-3xl font-extrabold w-full md:w-80 z-10 text-gray-700 dark:text-gray-200 mt-8 text-center md:text-right p-4 md:p-0"
                    >
                        Rapid Prototyping using variables and logic flows to render dynamic content.
                    </motion.h3>
                </div> */}
            </div>

            {/* ----------------Heuristics development----------------------- */}
            
            <div className="relative block mt-24 mb-12">
                <div className="container mx-auto px-4">
                    <h6 className="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500">
                        TRUST AND SAFETY
                    </h6>
                    <h3 className="text-2xl md:text-3xl font-extrabold text-gray-700 dark:text-gray-200">
                        Trust issues with technology and AI for the elderly.
                    </h3>
                    <h4 className="text-xl md:text-2xl font-semibold mt-4 mb-2 text-gray-600 dark:text-gray-200">
                        Collaborated with clinical team, UHG Accessibility team and third-party vendors to develop user testing plan.
                    </h4>
                    <div className="flex flex-col relative rounded-xl mt-4 bg-stone-300/80 dark:bg-gray-800">
                        <div className="m-8">
                            <ul className="p-0 list-none">
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none-4 my-4">Considering the needs of older adult participants. Visual impairment, Hearing impairment, Motor control, Cognitive load, Memory load, and Environmental factors.</li>
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none-4 my-4">Plan and setup. Optimal user recruitment for the study, defining clear goals and objectives.</li>
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none-4 my-4">Usability test. Use task that arerelevant to users, provide copy of each task, build trust with participants.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">- While resources onboarding is easy, the access can be granted to anyone, but we found out later that it might compromise the resource integrity this reducing user base.</li>
                            </ul>
                        </div>
                    </div>    
                    <h4 className="text-xl md:text-2xl font-semibold mt-6 mb-2 text-gray-600 dark:text-gray-200">
                        Usability testing on functional prototype.
                    </h4>
                    <img src={require(`../Images/previsit/participants.png`)} alt="" className="w-full lg:mb-4 md:w-auto z-0 mb-4 md:mb-0 md:mr-4 rounded-xl" />
                    <img src={require(`../Images/previsit/figma-prototype.png`)} alt="" className="w-full lg:mb-4 md:w-auto z-0 mb-4 md:mb-0 md:mr-4 rounded-xl" />
                    <h4 className="text-xl md:text-2xl font-semibold mt-6 mb-2 text-gray-600 dark:text-gray-200">
                        Revelations on usability testing with elderly. 
                    </h4>
                    <div className="flex flex-col items-start relative rounded-xl bg-stone-300/80 dark:bg-gray-800">
                        <div className="m-8">
                            <ul className="p-0 list-none">
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">
                                    Most users were comfortable with the AI agent, and found it helpful. Users could follow the chain of thought process and provide answers.
                                </li>
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none-4 my-4">
                                    Enhanced Communication: Clearly communication the estimated completion time (15 minutes), share benefits of completing the form in advance.
                                </li>
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none-4 my-4">
                                    Improved Accessibility: Design updates should account for varied user interactions, such as zoom, touchscreen, and interruptions. Include a "save and return" feature to accommodate returning users.
                                </li>
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none-4 my-4">
                                    Additional Fields for Home Visits: Introduce optional fields for specific access instructions (e.g., “use side door”) and presence of pets to better prepare for home visit scenarios.
                                </li>
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none-4 my-4">
                                    Post-Submission Enhancements: Revise the final screen to include instructions for modifying or canceling appointments, and clarify form access post submission.
                                </li>
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">
                                    Outlier scenarios that the design system can't accommodate, thus tweaking component for senior friendly interface.
                                </li>
                            </ul>
                        </div>
                    </div>
                   
                </div>
            </div>
           

            <div className="block relative mt-24 mb-12">
                <div className="container mt-12 px-4 sm:px-6 lg:px-8"> 
                    {/* Unordered List  */}
                    <h6 className="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500">
                        CORRECTION COURSE
                    </h6>
                    <h3 className="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200">Confrontations by seniors towards AI platforms post task analysis.</h3>
                    <img src={require(`../Images/previsit/previsit-post-usability-test.png`)} alt="" className="w-full lg:mb-4 md:w-auto z-0 mb-4 md:mb-0 md:mr-4 rounded-xl" />
                    <img 
                            src={require(`../Images/previsit/previsit-context-sense4.png`)} 
                            className="w-full mt-4 lg:mb-4 md:w-auto z-0 mb-4 md:mb-0 md:mr-4 rounded-xl" 
                            alt=""
                    />  
                    <h4 className="text-xl md:text-2xl font-semibold mt-4 mb-2 text-gray-600 dark:text-gray-200">
                        Key highlights
                    </h4>
                    <div className="flex flex-col items-start mt-4 relative rounded-xl bg-stone-300/80 dark:bg-gray-800">
                        <div className="m-8">
                            <ul className="p-0 list-none">
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">
                                    Predictive Allergy Mapping: 78% accuracy in pre-identifying allergens before visit. Reduces assessment time by 40% via auto-populated suggestions.
                                </li>
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none-4 my-4">
                                    Cognitive Load Reduction: 92% completion of visual workflows vs 64% for text-based workflows.
                                </li>
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none-4 my-4">
                                    Real-Time Clinical Integration: Auto-flags 100% of medication conflicts during entry. 
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            {/* <div className="mt-8">
                <ImageSwiper images={interactionValidation} />
            </div> */}

            {/* <div className="block relative mt-24 mb-12">
                <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                    <h6 className="font-mono text-lg md:text-base font-semibold mb-2 my-4 mt-12 tracking-widest text-indigo-500">
                        USABILITY EVALUATION WITH SENIORS
                    </h6>
                    <h3 className="text-2xl md:text-3xl font-extrabold mb-2 text-gray-700 dark:text-gray-200">
                        Usability testing with seniors
                    </h3>
                    <p className="text-lg md:text-base text-gray-600 dark:text-gray-400">
                        Uncovered critical insights into interface design that enhances learning and interaction for elderly users.
                    </p>
                    <img src={require(`../Images/previsit/participants.png`)} alt="" className="w-full md:w-auto" />
                    <div className="flex flex-col items-start relative rounded-xl mt-4 bg-stone-300/80 dark:bg-gray-800">
                        <div className="m-8">
                            <h4 className="text-xl md:text-2xl font-semibold mb-2 text-gray-100 dark:text-gray-400">
                                Outcomes from moderated user testing
                            </h4>
                            <ul className="p-0 list-none">
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none-4 my-4">
                                    Enhanced Communication: Clearly communicate the estimated completion time (15 minutes), share benefits of completing the form in advance.
                                </li>
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none-4 my-4">
                                    Improved Accessibility: Design updates should account for varied user interactions, such as zoom, touchscreen, and interruptions. Include a "save and return" feature to accommodate returning users.
                                </li>
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none-4 my-4">
                                    Additional Fields for Home Visits: Introduce optional fields for specific access instructions (e.g., “use side door”) and presence of pets to better prepare for home visit scenarios.
                                </li>
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none-4 my-4">
                                    Post-Submission Enhancements: Revise the final screen to include instructions for modifying or canceling appointments, and clarify form access post submission.
                                </li>
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">
                                    Outlier scenarios that the design system can't accommodate, thus tweaking component for senior friendly interface.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* Final Deliverable */}
            <div className="block relative mt-24 mb-12">
                <div className="container">
                    <h6 className="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500">
                        FURTHER DETAILS
                    </h6>
                    <br></br>
                    <h3 className="text-2xl md:text-3xl font-bold mb-4 text-gray-700 dark:text-gray-200">Let’s connect to explore the detailed findings behind the design of a senior-friendly digital platform. This solution effectively addresses cognitive, physical, and environmental challenges, streamlining onboarding while enhancing accessibility and user experience.</h3>                    
                    
                    <p className="text-lg mb-8 md:text-base text-gray-600 dark:text-gray-400">
                        Let's connect, drop me at mail at abhi.saraf25@gmail.com
                    </p>
                </div>
            </div>
            </div>
        </>
    )
};

export default Previsit;