import React from "react";
import { LayoutGrid, Building2, GraduationCap } from 'lucide-react';
import { motion } from 'framer-motion';

const CardTabs = ({ selectedCategory, onCategoryChange }) => {
    const isActiveTab = (category) => selectedCategory === category;

    return (
        <motion.div
            initial={{ y:+50, opacity: 0 }}
            animate={{ y:0, opacity: 1 }}
            transition={{ ease:"easeOut", duration:0.5 }}
            className="container"
        >
            <div className="flex flex-row gap-2 mb-4 pb-2 border-b border-solid border-slate-500">
                {/* All Categories Tab */}
                <button 
                    className={`flex rounded-lg p-3 md:p-4 justify-center md:px-10 transition-all duration-200 ${
                        isActiveTab('all') 
                            ? 'bg-stone-300 dark:bg-gray-800 text-gray-700 dark:text-white' 
                            : 'text-gray-700 dark:text-gray-300 hover:bg-stone-300 dark:hover:bg-gray-800'
                    }`}
                    onClick={() => onCategoryChange('all')}
                >
                    <LayoutGrid size={20}/>
                    <h6 className="hidden md:block text-base ml-2 m-0">All categories</h6>
                </button>

                {/* Professional Tab */}
                <button 
                    className={`flex rounded-lg p-3 md:p-4 justify-center md:px-10 transition-all duration-200 ${
                        isActiveTab('professional') 
                            ? 'bg-stone-300 dark:bg-gray-800 text-gray-700 dark:text-white' 
                            : 'text-gray-700 dark:text-gray-300 hover:bg-stone-300 dark:hover:bg-gray-800'
                    }`}
                    onClick={() => onCategoryChange('professional')}
                >
                    <Building2 size={20}/>
                    <h6 className="hidden md:block text-base ml-2 m-0">Professional</h6>
                </button>

                {/* Academic Tab */}
                <button 
                    className={`flex rounded-lg p-3 md:p-4 justify-center md:px-10 transition-all duration-200 ${
                        isActiveTab('academic') 
                            ? 'bg-stone-300 dark:bg-gray-800 text-gray-700 dark:text-white' 
                            : 'text-gray-700 dark:text-gray-300 hover:bg-stone-300 dark:hover:bg-gray-800'
                    }`}
                    onClick={() => onCategoryChange('academic')}
                >
                    <GraduationCap size={20}/>
                    <h6 className="hidden md:block text-base ml-2 m-0">Academic</h6>
                </button>
            </div>
        </motion.div>
    );
};

export default CardTabs;