import React from "react";

const InfoCard = ({ label, items = [] }) => {
  if (!items.some(item => item)) return null;

  return (
    <div className="flex-shrink-0 w-[85vw] md:w-full flex flex-col items-start p-6 relative rounded-xl bg-stone-300/80 dark:bg-gray-800">
      <p className="text-sm md:text-base font-semibold mb-2 text-gray-600 dark:text-white leading-relaxed">
        {label}
      </p>
      <ul className="list-none space-y-2 pl-0 md:pl-4">
        {items.map((text, index) => (
          text && (
            <li
              key={index}
              className="flex items-start gap-2 text-[11px] md:text-base text-gray-600 dark:text-gray-400 leading-relaxed"
            >
              <span className="inline-block w-1.5 h-1.5 mt-[0.28rem] md:mt-2.5 rounded-full bg-gray-600 dark:bg-gray-400 shrink-0"></span>
              <span>{text}</span>
            </li>
          )
        ))}
      </ul>
    </div>
  );
};

const ProjectHeroPanel = ({
  image,
  text1 = "",
  text2 = "",
  text3 = "",
  text4 = "",
  text5 = "",
  text6 = "",
  text7 = "",
  text8 = "",
  text9 = ""
}) => {
  const sections = [
    {
      label: "Key contributions",
      items: [text1, text2, text3]
    },
    {
      label: "Team",
      items: [text4, text5, text6]
    },
    {
      label: "Duration",
      items: [text7, text8, text9]
    }
  ];

  return (
    <div className="block relative mt-12 mb-36">
      <div className="container grid">
        {image && (
          <div className="flex flex-col items-start w-full relative rounded-xl overflow-hidden bg-stone-300/80 dark:bg-gray-800">
            <img 
              src={require(`../Images/projectHeroPanel/${image}`)} 
              alt="" 
              className="rounded-2xl w-full h-auto transform hover:scale-105 transition-transform duration-300"
            />
          </div>
        )}

        {/* Scroll Container with Fixed Indicator */}
        <div className="relative ">
          {/* Scrollable Content */}
          <div className="overflow-x-auto md:overflow-visible -mx-4 px-4 md:mx-0 md:px-0">
            <div className="flex md:grid md:grid-cols-3 gap-4 pt-4 md:pb-0">
              {sections.map((section, index) => (
                <InfoCard
                  key={index}
                  label={section.label}
                  items={section.items}
                />
              ))}
            </div>
          </div>
          
          {/* Fixed Position Scroll Indicator */}
          <div className="sticky left-0 right-0 bottom-0 md:hidden bg-stone-200/80 dark:bg-gray-950/80 backdrop-blur-sm py-2">
            <div className="flex justify-center gap-1">
              {sections.map((_, index) => (
                <div 
                  key={index} 
                  className="w-1 h-1 rounded-full bg-gray-400 dark:bg-gray-600"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectHeroPanel;