import React, { useState } from "react";
import CardTabs from "./CardTabs";
import ProjectList from "./ProjectList";
import PasswordModal from "./PasswordModal";

const ProjectSection = () => {
    const [selectedCategory, setSelectedCategory] = useState('professional');
    const [showModal, setShowModal] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    };

    const handleProjectClick = (projectId, isSecure) => {
        if (isSecure) {
            setSelectedProject(projectId);
            setShowModal(true);
        }
    };

    return (
        <div className="pt-8">
            <div id="work-section">
                <CardTabs 
                    selectedCategory={selectedCategory} 
                    onCategoryChange={handleCategoryChange} 
                />
                
                <ProjectList 
                    selectedCategory={selectedCategory}
                    onProjectClick={handleProjectClick}
                />

                {showModal && (
                    <PasswordModal 
                        projectId={selectedProject}
                        onClose={() => setShowModal(false)}
                    />
                )}
            </div>
        </div>
    );
};

export default ProjectSection; 