import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import { motion } from 'framer-motion';
import Toggle from '../toggle';
import logoImage from '../Images/60x60.png';
import emailIcon from '../Images/email.png';
import githubIcon from '../Images/github.png';
import linkedinIcon from '../Images/linkedin.png';

const Nav = ({ isDarkMode, setIsDarkMode }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggle = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMenuOpen]);

  return (
    <motion.div 
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ ease: "easeOut", duration: 0.5 }} 
      className='container'
    > 
      <nav className=" py-2 relative rounded-lg bg-stone-300 shadow-2xl shadow-slate-400 border border-stone-300 dark:bg-slate-950/70 dark:shadow-3xl dark:shadow-slate-700/30 dark:border dark:border-slate-800">
        <div className='flex justify-between items-center'>
          <button 
            className="md:hidden ml-4" 
            onClick={handleMenuToggle}
            aria-label="Toggle menu"
          >
            <svg className="w-6 h-6 text-gray-600 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
          <div className='flex items-center mr-4'>
              <a href="/" aria-current="page" className="border border-stone-500 dark:border-gray-600 rounded-md bg-stone-400 dark:bg-blue-700 p-0 pr-2 pl-2 ml-4  " aria-label="home">
                {/* <img src={logoImage} width="auto" height="auto" alt="web-logo" className="relative scale-75 ml-4" /> */}
                <div className='font-bakbak text-2xl  text-gray-200 dark:text-gray-200'>अ</div>
              </a>
              <a href="/" aria-current="page" aria-label="home">
                <h5 className="text-lg md:text-xl font-bold text-gray-600 dark:text-gray-200 leading-snug my-3 ml-2">
                  Abhijeet Saraf
                </h5>
              </a>
          </div>
          <div className="hidden md:flex justify-between items-center ml-auto mr-8 relative">
            {[
              ["/", "Home"],
              ["/writings", "Writings"],
              ["https://drive.google.com/file/d/1FeztXO-I19iHjuzyyO4OlnLcCAggGxTw/view?usp=drive_link", "Resume"],
              ["/aboutme", "About me"],
            ].map(([route, title]) => (
              <Link className="text-gray-500 hover:text-gray-900 dark:text-white/50 transition-colors duration-300 ease-in-out dark:hover:text-white mr-8" to={route} onClick={handleMenuItemClick}>{title}</Link>
            ))}
            <div className="flex items-center">
              {[
                ["mailto:abhi.saraf25@gmail.com", emailIcon],
                ["https://github.com/abhijeetsaraf", githubIcon],
                ["https://www.linkedin.com/in/abhijeet-saraf/", linkedinIcon],
              ].map(([link, icon]) => (
                <a target='blank' aria-current="page" href={link} className="mr-6">
                  <img className="opacity-50 transition-opacity duration-300 ease-in-out hover:opacity-100 [filter:brightness(0)_saturate(0)_opacity(0.6)] dark:brightness-0 dark:invert" width="auto" height="auto" alt="web-logo" src={icon} />
                </a>
              ))}
            </div>
            <Toggle isToggled={isDarkMode} onToggle={handleToggle} />
          </div>
        </div>
        {isMenuOpen && (
          <motion.div 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="relative inset-0 bg-stone-300 dark:bg-slate-950 z-50 flex flex-col items-start p-4"
          >
            <button 
              className="mb-4" 
              onClick={handleMenuToggle}
              aria-label="Close menu"
            >
              <svg className="w-6 h-6 text-gray-600 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
            {[
              ["/", "Home"],
              ["/writings", "Writings"],
              ["https://drive.google.com/file/d/1FeztXO-I19iHjuzyyO4OlnLcCAggGxTw/view?usp=drive_link", "Resume"],
              ["/aboutme", "About me"],
            ].map(([route, title]) => (
              <Link className="text-gray-500 hover:text-gray-900 dark:text-white/50 transition-colors duration-300 ease-in-out dark:hover:text-white mb-4" to={route} onClick={handleMenuItemClick}>{title}</Link>
            ))}
            <div className="flex items-center mb-4">
              {[
                ["mailto:abhi.saraf25@gmail.com", emailIcon],
                ["https://github.com/abhijeetsaraf", githubIcon],
                ["https://www.linkedin.com/in/abhijeet-saraf/", linkedinIcon],
              ].map(([link, icon]) => (
                <a target='blank' aria-current="page" href={link} className="mr-6">
                  <img className="opacity-50 transition-opacity duration-300 ease-in-out hover:opacity-100 [filter:brightness(0)_saturate(0)_opacity(0.6)] dark:brightness-0 dark:invert" width="auto" height="auto" alt="web-logo" src={icon} />
                </a>
              ))}
            </div>
            <Toggle isToggled={isDarkMode} onToggle={handleToggle} />
          </motion.div>
        )}
      </nav>
    </motion.div>
  );
};

export default Nav;