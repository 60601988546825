import './App.css';
import './index.css';
import Nav from './components/Navigation/Nav';
import Work from './components/Work/Work';
import Gimmick from './components/Gimmick/Gimmick';
import Resume from './components/Resume/Resume';
import Aboutme from './components/Aboutme/Aboutme';
import Dev from './components/Devmode/Dev';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState,useEffect } from 'react';
import Smartsales from './components/Project/Smartsales';
import Previsit from './components/Project/Previsit';
import Optum from './components/Project/Optum';
import Paceport from './components/Project/Paceport';
import Marysplace from './components/Project/Marysplace';
import Vocalamp from './components/Project/Vocalamp';
import WackAMole from './components/Project/WackAMole';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/ScrollToTop';
import ScrollToTopButton from './components/ScrollToTopButton';
//import Decorator from './components/Decorators';
import { motion, useScroll, useSpring } from 'framer-motion';
//import HeroGradient from './components/HeroGradient';
import WritingsPage from './components/Writings/WritingsPage';
import BlogPost from './components/Blog/BlogPost';

//import { useEffect } from 'react';

function App() {
    
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Check if there's a stored preference
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode !== null) {
        return JSON.parse(savedMode);
    }
    // Default to dark mode instead of checking system preference
    return true;  // Changed from false to true for default dark mode
});

useEffect(() => {
    // Update class and save preference whenever isDarkMode changes
    if (isDarkMode) {
        document.body.classList.add("dark");
    } else {
        document.body.classList.remove("dark");
    }
    localStorage.setItem('darkMode', JSON.stringify(isDarkMode));
}, [isDarkMode]);

/* System preference listener (commented out for now)
useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
        if (localStorage.getItem('darkMode') === null) {
            setIsDarkMode(e.matches);
        }
    };
    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
}, []);
*/

  
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 25,
    restDelta: 0.001
  });

  return (
    <div class="pt-6 bg-stone-200 transition-colors duration-500 dark:bg-gray-950">
      {/* Progress Indicator */}
      <motion.div className="fixed top-0 left-0 right-0 h-1 rounded bg-stone-400  dark:bg-gray-300 origin-left z-[1000000]" style={{ scaleX }} />
      
      {/* <HeroGradient /> */}
      {/* <Decorator /> */}
      <ScrollToTopButton />
      <Router basename="/">
        <ScrollToTop />
        <div className='z-50'>
        <Nav isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode}/>
        </div>
        <Routes>
          <Route path="/" element={<Work />} />
          <Route path="/gimmick" element={<Gimmick />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/aboutme" element={<Aboutme />} />
          <Route path="/dev" element={<Dev />} />
          <Route path="/project">
            <Route path="/project/previsit" element={<Previsit />} />
            <Route path="/project/optum" element={<Optum />} />
            <Route path="/project/smartsales" element={<Smartsales />} />
            <Route path="/project/paceport" element={<Paceport />} />
            <Route path="/project/marysplace" element={<Marysplace />} />
            <Route path="/project/vocalamp" element={<Vocalamp />} />
            <Route path="/project/whackamole" element={<WackAMole />} />
          </Route>
          <Route path="/writings" element={<WritingsPage />} />
          <Route path="/writings/:slug" element={<BlogPost />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
