import React from 'react';
import { Link } from 'react-router-dom';

const BlogCard = ({ id, title = "Untitled", description = "No description available", date = "Unknown date", category = "Uncategorized", tags = [], image }) => {
  return (
    <Link to={`/writings/${id}`} className="block h-full">
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 h-full'>
        {image && (
          <div className="h-[250px]">
            <img src={image} alt={title} className="w-full h-full object-cover rounded-xl" />
          </div>
        )}
        <div className="p-6 rounded-xl bg-stone-300 dark:bg-gray-800 hover:bg-stone-400 dark:hover:bg-gray-700 transition duration-300 h-[250px] flex flex-col">
          <div className="font-mono text-sm text-blue-600 dark:text-blue-500 mb-2">
            {category}
          </div>
          <h3 className="text-xl font-semibold text-gray-800 dark:text-white">{title}</h3>
          {/* <p className="text-sm text-gray-600 dark:text-gray-300 mt-2 line-clamp-3">{description}</p> */}
          <div className="mt-auto">
            <div className="flex flex-wrap gap-2 mb-2">
              {tags.map((tag) => (
                <span key={tag} className="text-xs text-gray-300 px-2 py-1 border border-white/20 bg-black/20 rounded-md">
                  {tag}
                </span>
              ))}
            </div>
            <time className="block text-sm text-gray-500 dark:text-gray-400">
              {date}
            </time>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
// p-1.5 border rounded-md text-nowrap border-white/20 bg-black/20