import React, { useState } from "react";
import Button from "../Button";
import "../components.css";
import Badge from "../Badge/Badge";
import { useNavigate } from "react-router-dom";

const ProjectCards = ({id,image,category,panelSubheading, cardHeading, cardSubheading, badgesArray, secureStatus, openModal}) => {    
    
    const navigate = useNavigate();
    const handleCardClick = (projectId) => {
        if(secureStatus){
            openModal(id);
        }else{
            navigate(`/project/${projectId}`);    
        }

        };
 
    
        return(
            <div class="block relative mb-12 md:mb-24"> 
               <div key={(`${cardHeading}`)} class="container grid">
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div class="flex">
                            <img src={require(`../Images/${image}`)} alt="" class="w-full rounded-xl"/>
                        </div>
                        <div class="flex flex-col items-start relative rounded-xl bg-stone-300/80 dark:bg-gray-800 min-h-[250px] md:min-h-[300px] overflow-y-auto">
                           <div class="w-full">
                                <div class="mt-4 md:mt-8 ml-4 md:ml-8 mr-4 md:mr-8 px-0 py-1">
                                    <h6 class="font-mono text-sm md:text-base lg:text-lg font-semibold mb-2 md:mb-4 tracking-widest text-blue-600 dark:text-blue-600">
                                    {panelSubheading}
                                    </h6>
                                    <h3 class="text-lg md:text-xl lg:text-3xl font-extrabold mb-2 text-gray-700 dark:text-gray-200">
                                    {cardHeading}
                                    </h3>
                                    <h4 class="text-base md:text-lg lg:text-2xl font-semibold mb-2 text-gray-600 dark:text-gray-400">
                                    {cardSubheading}
                                    </h4>
        
                                    <div class="mt-4 md:mt-8 flex flex-row flex-wrap items-start gap-2">{badgesArray.map((badge) => (
                                        <Badge label={badge} badgeColor={"bg-slate-700"}/>
                                    ))}
                                    </div>
                                </div>
                                
                                <div className="w-full px-4 pt-4 pb-4 md:px-0 md:pb-0 md:pt-0">
    <div className="static md:absolute bottom-8 left-4 md:left-8 w-full md:w-auto">
        <Button  
            label="View Designs" 
            onClick={() => handleCardClick(id)} 
            secure={secureStatus}
            className="w-full md:w-auto text-center"
            size="lg"
        />    
    </div>
</div>
                           </div>                          
                        </div>
                    </div>
                </div>
            </div>
        );
};

export default ProjectCards;

