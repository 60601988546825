import ProjectHeroPanel from "../ProjectHeroPanel/ProjectHeroPanel";
import ScrollToTopButton from "../ScrollToTopButton";
import ImageSwiper from "../ImageSwiper";
import "../components.css";
import { Link } from "react-router-dom";
import ImageHorizontalScroll from "../ImageHorizontalScroll";

const componentOverview = [
    { src: require(`../Images/optum/web-interaction-collection-1.png`), alt: 'Image 1' },
    { src: require(`../Images/optum/web-interaction-collection-2.png`), alt: 'Image 2' },
    { src: require(`../Images/optum/web-interaction-collection-3.png`), alt: 'Image 3' },
];

const componentGroup = [
    { src: require(`../Images/optum/text-inputs-1.png`), alt: 'Image 1' },
    { src: require(`../Images/optum/multi-inputs-1.png`), alt: 'Image 2' },
    { src: require(`../Images/optum/multi-inputs-2.png`), alt: 'Image 3' },
    { src: require(`../Images/optum/toggle.png`), alt: 'Image 3' },
    { src: require(`../Images/optum/other-1.png`), alt: 'Image 3' },
    { src: require(`../Images/optum/other-2.png`), alt: 'Image 3' },
    { src: require(`../Images/optum/other-3.png`), alt: 'Image 3' },
];

const houseCallsIntroImages = [
    { src: require(`../Images/previsit/intro-housecalls-1.png`), alt: 'Image 1' },
    { src: require(`../Images/previsit/intro-housecalls-2.png`), alt: 'Image 2' },
    { src: require(`../Images/previsit/intro-housecalls-3.png`), alt: 'Image 3' },
    { src: require(`../Images/previsit/intro-housecalls-4.png`), alt: 'Image 4' },
    { src: require(`../Images/previsit/intro-housecalls-5.png`), alt: 'Image 5' },
    { src: require(`../Images/previsit/intro-housecalls-6.png`), alt: 'Image 6' },
    { src: require(`../Images/previsit/intro-housecalls-7.png`), alt: 'Image 7' },
    { src: require(`../Images/previsit/intro-housecalls-8.png`), alt: 'Image 8' },
    { src: require(`../Images/previsit/intro-housecalls-9.png`), alt: 'Image 9' },
    { src: require(`../Images/previsit/intro-housecalls-10.png`), alt: 'Image 10' }];

function Optum(){
    
    return(
        <div>
            <ScrollToTopButton/>
            <ProjectHeroPanel
                image="optum-housecalls.png"
                label1="Key contributions"
                label2="Team"
                label3="Duaration"
                text1="Designing Medication, Diagnosis, Review of Systems Sections in Figma"
                text2="Modularizing/Contributing to component utilization and design for Optum Design Systems - Netra"
                text3="Developer assistance in implementing components via storybook guidance"
                text4="Associate Director of Nursing, 4 Technical Project Manger"
                text5="30-35 Developer and QA resources"
                text6="Senior UX Designer, UX Designer"
                text7="HouseCalls - May 2022 - Dec 2023"
                text8=""
                text9=""
            >
            </ProjectHeroPanel>

            
            {/* ----------------------------------------------Overview Section of the project----------------------------------------- */}
            <div class="block relative mt-8 mb-12">
                <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500  ">
                         OVERVIEW
                    </h6>
                    <h1 class="text-4xl md:text-5xl font-bold tracking-tight mb-4 text-gray-900 dark:text-gray-100 leading-tight">
                    Optum - HouseCalls
                    </h1>
                    <p class="text-lg  md:text-base mb-8 text-gray-600  dark:text-gray-400 ">
                        HouseCalls is an annual in-home clinical assessment for members of participating health plans.
                        The assessment can improve performance on key Star measures. A HouseCalls visit results in a
                        more complete and accurate identification of a member’s health conditions.
                    </p>
                    <a href="https://www.optum.com/en/business/health-plans/members/in-home-clinical-assessment.html" class=" text-indigo-600 dark:text-indigo-400 hover:underline font-medium">
                    Read about HouseCalls
                    </a>
                    <h4 class="text-xl md:text-2xl font-semibold mb-2 mt-8  text-gray-600 dark:text-gray-200 ">
                    Impact
                    </h4>
                    <div class="flex flex-col p-8 items-start w-full relative rounded-xl bg-gradient-to-r from-indigo-500 to-blue-500">
                        <h6 class="font-mono text-lg md:text-base font-semibold mb-2  tracking-widest  text-white">
                            FACTSHEET
                        </h6>
                        
                            <div class="grid grid-cols-3 gap-x-8 w-full mb-8">
                                <h2 class="text-3xl md:text-4xl font-semibold text-white leading-snug my-1">2.1M</h2>
                                <h2 class="text-3xl md:text-4xl font-semibold text-white leading-snug my-1">86%</h2>
                                <h2 class="text-3xl md:text-4xl font-semibold text-white leading-snug my-1">99%</h2>
                                <p class="text-lg md:text-base text-white leading-relaxed ">Visits including 61K virtual visits</p>
                                <p class="text-lg md:text-base text-white leading-relaxed ">Star gap closure rate</p>
                                <p class="text-lg md:text-base text-white leading-relaxed ">Member satisfaction</p>
                            </div>
                            <div class="grid grid-cols-3 gap-x-8 w-full">
                                <h2 class="text-3xl md:text-4xl font-semibold text-white leading-snug my-1">50</h2>
                                <h2 class="text-3xl md:text-4xl font-semibold text-white leading-snug my-1">91%</h2>
                                <h2 class="text-3xl md:text-4xl font-semibold text-white leading-snug my-1">95%</h2>
                                <p class="text-lg md:text-base text-white leading-relaxed ">States where HouseCalls<br/>is available</p>
                                <p class="text-lg md:text-base text-white leading-relaxed ">Repeat visit<br/>acceptance rate</p>
                                <p class="text-lg md:text-base text-white leading-relaxed ">Coding Accuracy</p>
                            </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-0 mt-16">
                        <div className="flex justify-center items-center">
                            <img src={require(`../Images/optum/housecalls-intro.png`)} alt="Mobile Prototype" className="w-auto  h-auto max-w-xs md:max-w-sm lg:max-w-md" />
                        </div>
                        <div className="p-8 pl-0 flex flex-col justify-center">
                            <h4 className="text-xl md:text-2xl font-semibold mb-2 mt-8 text-gray-600 dark:text-gray-200">
                                Problem statement
                            </h4>
                            <p className="text-lg md:text-base mb-8 text-gray-600 dark:text-gray-400">
                                Current HouseCalls interface is not user-friendly and lacks a structured approach to scribe vitals and medication data during the visit. This results in a longer visit duration and data inconsistencies.
                            </p>

                            <h4 className="text-xl md:text-2xl font-semibold mb-2 mt-8 text-gray-600 dark:text-gray-200">
                                Solution
                            </h4>
                            <p className="text-lg md:text-base mb-8 text-gray-600 dark:text-gray-400">
                                Design a structured approach to scribe vitals and medication data during the visit. Integrate design system and improve efficiency during visit.
                            </p>
                        </div>
                    </div>
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 mt-12 tracking-widest text-indigo-500">
                                    DESIGN CONTRIBUTION
                    </h6>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        
                        <div class="flex flex-col items-start relative rounded-xl p-8 bg-stone-300/80 dark:bg-gray-800">
                            <h5 class="text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed">Design of Active/Inactive medication user flows</h5>
                        </div>
                        <div class="flex flex-col items-start relative rounded-xl p-8 bg-stone-300/80 dark:bg-gray-800">
                            <h5 class="text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed">Interaction patterns for Review of Systems forms</h5>
                        </div>
                        <div class="flex flex-col items-start relative rounded-xl p-8 bg-stone-300/80 dark:bg-gray-800">
                            <h5 class="text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed">Design system implementation with Dev teams</h5>
                        </div>
                        
                    </div>
                    <hr class="mt-8 mb-8 border-slate-800" />
                </div>
            </div>
            <div class="block relative mt-24 mb-12">
                <div class="block relative mt-12 mb-12">
                    <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500 ">
                         DESIGN OF MEDICATION AND REVIEW OF SYSTEMS USER FLOWS
                    </h6>
                    <h3 class="text-2xl md:text-3xl font-extrabold  text-gray-700 dark:text-gray-200 ">
                        User research
                    </h3>
                    
                    {/* Unordered List  */}
                    <div class="flex flex-col items-start relative rounded-xl bg-stone-300/80 dark:bg-gray-800">
                        <div class="m-8">
                        <h4 className="text-xl md:text-2xl font-semibold text-gray-600 dark:text-gray-200 ">
                            Context
                        </h4>
                        <ul class="p-0 list-none">
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">HouseCalls provides in-home visits for members (45 -60 mins) and works with health plans to address undiagnosed health conditions, aiming to improve outcomes and reduce costs.</li>
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Members receive personalized attention from Advanced Practice Clinicians, including a comprehensive exam, medication review, and health screenings , all within the comfort of their own homes.</li>
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">The program offers educational materials and resources tailored to each member's healthcare needs, promoting better understanding and management of their health.</li>
                        </ul>
                        </div>
                    </div>              
                
                    <h4 className="text-xl md:text-2xl font-semibold text-gray-600 dark:text-gray-200 -mb-24 mt-8">
                        Storyboard
                    </h4>
                    <ImageHorizontalScroll image={houseCallsIntroImages}/>
                    <div className="flex flex-col relative rounded-xl mt-4 bg-stone-300/80 dark:bg-gray-800">
                        <div class="m-8">
                        <h4 class="text-xl md:text-2xl font-semibold mb-2  text-gray-600 dark:text-gray-400">
                            Research questions for APCs Interview
                        </h4>
                        
                        <ul class="p-0 list-none">
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none-4 my-4">What is Medication process in context of house visits?</li>
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none my-4">What are the outliers in and data inconsistencies in current records?</li>
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none my-4">What are other pages of application where medication data is pivotal?</li>
                            <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-none ">Would users have a shared view of interface while visit?</li>
                        </ul>
                        </div>
                    </div>
                    {/* Unordered List  */}
                    
                    {/* Unordered List  */}
                    <div className="flex flex-col items-start relative rounded-xl mt-4 bg-stone-300/80 dark:bg-gray-800">
                        <div class="m-8">
                        <h4 class="text-xl md:text-2xl font-semibold mb-2  text-gray-600 dark:text-gray-400 ">
                        Interview outcomes
                        </h4>
                            <ul class="p-0 list-none">
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading--none-4 my-4">While collecting medication information members might provide false data, they may be consuming over-the-counter available medication, or might be taking herbal medication.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading--none-4 my-4">A recent hospitalization will require APC to gather complete information on the medication completed by the members, this can impact regular medication records.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading--none-4 my-4">Medication data include quantity, time, frequency, last fill data, Non-adherence to medication, Active/Inactive, and more.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading--none-4 my-4">Apart from collecting medication APCs are obligated to update any past pending or missing record for medication. </li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Outlier scenarios can include caregiver not present, member diagnosed with Dimentia/Alzhemiers, original bottles not available.</li>
                            </ul>
                        </div>
                    </div>
                     
                        
                </div>

                
            </div>
        </div>

            {/*------------------------------------------ Active/Inactive Medication Section--------------------------------------- */}
            <div class="block relative mt-24 mb-12">
                <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 mt-12 tracking-widest text-indigo-500">
                                    INTERACTION DESIGN
                    </h6>
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-2 text-gray-700 dark:text-gray-200 ">
                         Design artifacts
                    </h3>
                    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div class="flex flex-col col-span-1 md:col-span-1 items-start relative rounded-xl bg-stone-300/80 dark:bg-gray-800">
                            <div class="m-8">
                                
                                <h4 class="text-xl md:text-2xl font-semibold mb-2  text-gray-600 dark:text-white" >Wireframes</h4>
                                <p class="text-lg mb-8 md:text-base  text-gray-600  dark:text-gray-400">Ideated 3 versions to separate data and presented to APCs. Key outcomes include a two step approach, first allow APC to collect information for past medication and later mark Active/Inactive medications.</p>
                                
                            </div>
                        </div>
                        <div className="flex col-span-1 md:col-span-2 flex-col items-start relative rounded-xl bg-stone-300/80 dark:bg-gray-800">
                            <img src={require(`../Images/optum/web-wf-collection-1.png`)}  alt="" className="grid-image "/>
                        </div>
                    </div>
                    <h4 className="text-xl md:text-2xl font-semibold text-gray-600 dark:text-gray-200 mt-8">
                        Interface Design
                    </h4>
                    <img src={require(`../Images/optum/project-hero.png`)}  alt="" class="rounded-xl"/>   
                
                </div>

                
                {/* Outcomes and Interface Suggestions */}
                
                <div class="container mt-8">
                    <h4 class="text-xl md:text-2xl font-semibold mb-2  text-gray-600 dark:text-white ">
                        Design systems and components
                    </h4>
                    {/* <div className="space-bottom-extra-large">
                        <ImageSlider images={componentOverview} />
                        
                    </div> */}
                </div>
                <ImageSwiper images={componentOverview}  />


            </div>

            {/*------------------------------------------ Active/Inactive Medication Section--------------------------------------- */}
            <div class="block relative mt-24 mb-12 ">
                <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500">
                                    REVIEW OF SYSTEMS
                    </h6>
                    <h3 class="text-2xl md:text-3xl font-extrabold mb-2 text-gray-700 dark:text-white ">
                        Researched faster execution of Review of Systems forms.
                    </h3>
                    <p class="text-lg mb-8 md:text-base  text-gray-600  dark:text-gray-400">The Clinicians have limited time allocation per member visit, this requires the system to leverage available time and onboard user data efficiently. 
                    </p>
                </div>

                {/* Research */}
                <div class="container">
                    {/* Unordered List  */}
                    <div class="flex flex-col relative rounded-xl bg-stone-300/80 dark:bg-gray-800">
                        <div class="m-8">
                            <h4 class="text-xl md:text-2xl font-semibold mb-2  text-gray-600 dark:text-white" >Research performed on Review of Systems data.</h4>
                            <ul className="list-none p-0">
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading--none-4 my-4">Exploration of form data that will be collected from the member. </li>
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading--none-4 my-4">What atoms of the design system can be leveraged to build a consistent and modular form? </li>
                                <li className="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading--none-4 ">How to future-proof the system for different data collection processes. </li>
                            </ul>
                        </div>
                    </div>
                </div>


                {/* Outcomes and Interface Suggestions */}
                <div class="container">
                    <h4 class="text-xl md:text-2xl font-semibold mb-2  text-gray-600 dark:text-white mt-8" >Outcomes and interaction suggestions</h4>
                    <p class="text-lg mb-8 md:text-base  text-gray-600  dark:text-gray-400">Created possible states derived from Optum’s design system Netra and helped developers build and test all component instances in storybook js. This resulted in overall perfection in interaction patterns across all forms in HouseCalls.</p>
                    {/* <div className="space-bottom-extra-large">
                        <ImageSlider images={componentGroup} />
                    </div> */}
                </div>
                <ImageSwiper images={componentGroup}/>
            </div>
            
            {/* Final Deliverable */}
            <div class="block relative mt-24 mb-12">
                <div class="container">
                    <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500">
                            FURTHER DETAILS
                    </h6>
                    <br></br>
                    <h3 class="text-2xl md:text-3xl font-bold mb-4 text-gray-700 dark:text-gray-200">Led the integration of rapid design systems across various HouseCalls applications and pages, driving notable improvements in design and code modularity. This initiative streamlined development processes, fostering efficiency and ensuring consistent user experiences.</h3>
                    <p class="text-lg mb-8 md:text-base  text-gray-600  dark:text-gray-400 ">Let's connect or drop me a mail at abhi.saraf25@gmail.com</p>
                </div>
            </div>
        </div>
    )
};

export default Optum;