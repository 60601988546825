import matter from 'gray-matter';

export const getBlogFiles = () => [
    'systems-approach-to-design',
    'devx-prompt'
];

export const loadBlogPost = async (slug) => {
    try {
        const response = await fetch(`/src/content/blogs/${slug}.md`);
        if (!response.ok) {
            throw new Error(`Failed to fetch blog: ${response.statusText}`);
        }
        const text = await response.text();
        const { data: frontmatter, content } = matter(text);
        
        console.log(`Parsed frontmatter for ${slug}:`, frontmatter); // Debug log
        console.log(`Content for ${slug}:`, content); // Debug log

        // Use a default image if the specified one is not found
        let image;
        try {
            image = require(`../components/Images/${frontmatter.image || 'backdrop.jpeg'}`);
        } catch (e) {
            console.warn(`Image not found for ${slug}, using default image.`);
            image = require('../components/Images/backdrop.jpeg');
        }

        return {
            id: slug,
            title: frontmatter.title,
            description: frontmatter.description,
            date: frontmatter.date,
            category: frontmatter.category,
            tags: frontmatter.tags,
            image
        };
    } catch (error) {
        console.error(`Error loading blog ${slug}:`, error);
        return null;
    }
};

export const loadAllBlogs = async () => {
    const blogFiles = getBlogFiles();
    const blogPosts = await Promise.all(
        blogFiles.map(loadBlogPost)
    );
    
    // Filter out any failed loads and sort by date
    return blogPosts
        .filter(post => post !== null)
        .sort((a, b) => new Date(b.date) - new Date(a.date));
};