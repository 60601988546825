import React from "react";
import PropTypes from "prop-types";
import "../components.css";

const Badge = ({label, badgeColor}) => {
    
    const badgeClass = `
    rounded-md
    inline-flex
    items-center
    justify-center
    bg-slate-600
    border
    border-slate-500
    text-white
    text-[10px]
    md:text-sm
    min-h-[1.5rem]
    md:min-h-[2rem]
    px-2
    md:px-3
    py-0.5
    md:py-1
    whitespace-normal
    break-words
    text-center
    ${badgeColor}`;

    return(
        <span className={badgeClass}>{label}</span>
    );
}

Badge.propTypes = {
    badgeColor: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
}

export default Badge;