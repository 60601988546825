import React from "react";
import cardData from "../../cardData";
import ProjectCards from "../Cards/ProjectCards";
import {animate, delay, easeInOut, useTransform,useScroll, motion} from 'framer-motion';


const ProjectList = ({ selectedCategory, onProjectClick }) => {
    const filteredProjects = cardData.filter(card => {
        if(selectedCategory === 'all') return true;
        return card.category === selectedCategory;
    });

    return (
        <motion.div
            initial={{ y:+50, opacity: 0 }}
            animate={{ y:0, opacity: 1 }}
            transition={{ ease:"easeOut", duration:0.5 }}
            className="mt-0"
        >
            {filteredProjects.map(project => (
                <ProjectCards
                    key={project.id}
                    category={project.category}
                    id={project.id}
                    image={project.image}
                    panelSubheading={project.panelSubheading}
                    cardHeading={project.heading}
                    cardSubheading={project.subheading}
                    badgesArray={project.badges}
                    secureStatus={project.secure}
                    onClick={() => onProjectClick(project.id, project.secure)}
                />
            ))}
        </motion.div>
    );
};

export default ProjectList; 