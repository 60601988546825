import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../Button";

const PasswordModal = ({ projectId, onClose }) => {
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const correctPassword = "@Recruiter2024";

    const handleSubmit = () => {
        if(password === correctPassword) {
            navigate(`/project/${projectId}`);
            onClose();
        } else {
            setError("Please enter correct password");
        }
    };

    return (
        <div className="flex justify-center items-center fixed top-0 left-0 w-full h-full bg-slate-900 z-[999]">
            {/* Modal content */}
        </div>
    );
};

export default PasswordModal; 