import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import matter from 'gray-matter';
import BlogLayout from './BlogLayout';
import { Buffer } from 'buffer';

// Add this line to make Buffer available globally
global.Buffer = Buffer;

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState({ content: '', frontmatter: {} });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true);
      try {
        const response = await fetch(`/src/content/blogs/${slug}.md`);
        if (!response.ok) {
          throw new Error(`Failed to load blog post: ${response.statusText}`);
        }
        const text = await response.text();
        const { data, content } = matter(text);
        setPost({ content, frontmatter: data });
      } catch (error) {
        console.error('Error loading blog post:', error);
        setError('Failed to load blog post. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug]);
  // ... existing imports and state management ...


  const customComponents = {
    h1: ({ children }) => (
      <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold tracking-tight text-gray-900 dark:text-gray-100 leading-tight my-4 sm:my-5 md:my-6">
        {children}
      </h1>
    ),
    h2: ({ children }) => (
      <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold text-gray-800 dark:text-gray-200 leading-snug my-4 sm:my-4 md:my-5">
        {children}
      </h2>
    ),
    h3: ({ children }) => (
      <h3 className="text-xl sm:text-2xl md:text-3xl font-medium text-gray-700 dark:text-gray-300 leading-normal my-3 sm:my-3 md:my-4">
        {children}
      </h3>
    ),
    h4: ({ children }) => (
      <h4 className="text-lg sm:text-xl md:text-2xl font-medium text-gray-600 dark:text-gray-400 leading-snug my-3 sm:my-3 md:my-4">
        {children}
      </h4>
    ),
    p: ({ children }) => (
      <p className="text-base sm:text-lg md:text-base text-gray-600 dark:text-gray-400 leading-relaxed my-3 sm:my-4 md:my-4">
        {children}
      </p>
    ),
    ul: ({ children }) => (
      <ul className="list-disc pl-4 sm:pl-5 md:pl-6 my-3 sm:my-4 md:my-4 text-base text-gray-600 dark:text-gray-400">
        {children}
      </ul>
    ),
    ol: ({ children }) => (
      <ol className="list-decimal pl-4 sm:pl-5 md:pl-6 my-3 sm:my-4 md:my-4 text-base text-gray-600 dark:text-gray-400">
        {children}
      </ol>
    ),
    li: ({ children }) => (
      <li className="mb-2">{children}</li>
    ),
    a: ({ href, children }) => (
      <a href={href} className="text-indigo-600 dark:text-indigo-400 hover:underline font-medium inline-block">
        {children}
      </a>
    ),
    img: ({ src, alt }) => (
      <div className="my-6">
        <img
          src={src}
          alt={alt}
          className="rounded-lg shadow-lg mx-auto w-full"
          loading="lazy"
        />
        {alt && <div className="mt-2 text-sm text-center text-gray-500 dark:text-gray-400">{alt}</div>}
      </div>
    ),
    hr: () => (
      <hr className="border-slate-200 dark:border-slate-800 my-6" />
    ),
    code: ({ node, inline, className, children, ...props }) => {
      const match = /language-(\w+)/.exec(className || '');
      return !inline && match ? (
        <SyntaxHighlighter
          style={tomorrow}
          language={match[1]}
          className="rounded-xl my-4 text-slate-300 bg-gray-900"
          {...props}
        >
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
      ) : (
        <code className="bg-gray-100 dark:bg-gray-800 px-2 py-1 rounded-md font-mono text-sm text-gray-900 dark:text-gray-100">
          {children}
        </code>
      );
    },
  };

  if (loading) {
    return (
      <BlogLayout>
        <div className="text-center py-8 md:py-12">
          <h2 className="text-xl md:text-2xl font-mono text-gray-800 dark:text-white">Loading...</h2>
        </div>
      </BlogLayout>
    );
  }

  if (error) {
    return (
      <BlogLayout>
        <div className="text-center py-8 md:py-12">
          <h2 className="text-xl md:text-2xl font-mono text-gray-800 dark:text-white">{error}</h2>
        </div>
      </BlogLayout>
    );
  }

  return (
    <BlogLayout>
      <div className="block relative mt-8 md:mt-24 mb-8 md:mb-24">
        <div className="w-full md:w-[70vw] lg:w-[50vw] mx-auto px-4 md:px-6 lg:px-8">
          <header className="mb-6 md:mb-8">
            <h6 className="font-mono text-xs md:text-sm font-semibold mb-2 tracking-widest text-indigo-500 dark:text-indigo-400">
              {post.frontmatter.category}
            </h6>
            <h1 className="text-2xl md:text-4xl lg:text-5xl font-bold tracking-tight text-gray-900 dark:text-gray-100 leading-tight">
              {post.frontmatter.title}
            </h1>
            <div className="mt-3 md:mt-4 flex flex-wrap gap-2">
              {post.frontmatter.tags?.map((tag) => (
                <span 
                  key={tag} 
                  className="px-2 md:px-3 py-1 bg-gray-200 dark:bg-gray-700 rounded-full text-xs md:text-sm text-gray-700 dark:text-gray-300"
                >
                  {tag}
                </span>
              ))}
            </div>
            <time className="block mt-3 md:mt-4 text-sm md:text-base text-gray-600 dark:text-gray-400">
              {post.frontmatter.date}
            </time>
          </header>

          {/* Update the custom components for better mobile responsiveness */}
          <ReactMarkdown components={{
            ...customComponents,
            h1: ({ children }) => (
              <h1 className="text-2xl md:text-4xl lg:text-5xl font-bold tracking-tight text-gray-900 dark:text-gray-100 leading-tight my-3 md:my-6">
                {children}
              </h1>
            ),
            h2: ({ children }) => (
              <h2 className="text-xl md:text-3xl lg:text-4xl font-semibold text-gray-800 dark:text-gray-200 leading-snug my-3 md:my-5">
                {children}
              </h2>
            ),
            h3: ({ children }) => (
              <h3 className="text-lg md:text-2xl lg:text-3xl font-medium text-gray-700 dark:text-gray-300 leading-normal my-2 md:my-4">
                {children}
              </h3>
            ),
            p: ({ children }) => (
              <p className="text-sm md:text-base text-gray-600 dark:text-gray-400 leading-relaxed my-2 md:my-4">
                {children}
              </p>
            ),
            ul: ({ children }) => (
              <ul className="list-disc pl-4 md:pl-6 my-2 md:my-4 text-sm md:text-base text-gray-600 dark:text-gray-400">
                {children}
              </ul>
            ),
            img: ({ src, alt }) => (
              <div className="my-4 md:my-6">
                <img
                  src={src}
                  alt={alt}
                  className="rounded-lg shadow-lg mx-auto w-full"
                  loading="lazy"
                />
                {alt && (
                  <div className="mt-2 text-xs md:text-sm text-center text-gray-500 dark:text-gray-400">
                    {alt}
                  </div>
                )}
              </div>
            ),
            code: ({ node, inline, className, children, ...props }) => {
              const match = /language-(\w+)/.exec(className || '');
              return !inline && match ? (
                <SyntaxHighlighter
                  style={tomorrow}
                  language={match[1]}
                  className="rounded-xl my-3 md:my-4 text-sm md:text-base text-slate-300 bg-gray-900 overflow-x-auto"
                  {...props}
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              ) : (
                <code className="bg-gray-100 dark:bg-gray-800 px-1.5 md:px-2 py-0.5 md:py-1 rounded-md font-mono text-xs md:text-sm text-gray-900 dark:text-gray-100">
                  {children}
                </code>
              );
            },
          }}>
            {post.content}
          </ReactMarkdown>
        </div>
      </div>
    </BlogLayout>
  );
};

export default BlogPost;
