import React from "react";
import ProjectHeroPanel from "../ProjectHeroPanel/ProjectHeroPanel";
import ScrollToTopButton from "../ScrollToTopButton";
import "../components.css";

function WackAMole(){
    
        return(
            <div>
                <ScrollToTopButton/>
                <ProjectHeroPanel
                    image="whack-a-cover.png"
                    label1="Key contributions"
                    label2="Team"
                    label3="Duaration"
                    text1="Generated innovative ideas around dynamic board games and solving issues around cognitive abilities."
                    text2="Hardware prototyping and design in Fusion 360."
                    text3="Interfaced PPG sensor using Arduino."
                    text4="‍Hardware Prototyper"
                    text5="2 Software developer and ML Engineer"
                    text6="User researcher"
                    text7="3 Months"                    
                    text8=""
                    text9=""
                >
    
                </ProjectHeroPanel>

                {/* Overview Section of the project */}
                <div class="block relative mt-12 mb-12">
                    <div class="container">
                        <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500">
                            OVERVIEW
                        </h6>
                        <h1 class="text-4xl md:text-5xl font-bold tracking-tight mb-4 text-gray-900 dark:text-gray-100 leading-tight">
                        Whack-a-mole
                        </h1>
                        <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200 ">Gamified cognitive assessment through dynamic board game.</h3>
                        <p class="text-lg  md:text-base mb-8 text-gray-600  dark:text-gray-400 ">Print books are proven to be crucial to reading comprehension for young children as compared to digital books. The child can point the finger at the word and can learn about the new words they encounter.</p>
                        <div class="flex flex-col rounded-xl p-8 mt-8 bg-stone-300/80 dark:bg-gray-800">
                            <h5 class="text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed">Memory | Reaction Speed | Accuracy | Heartrate</h5>
                        </div>
                    </div>
                </div>


                {/* Problem Statement */}
                <div class="block relative mt-12 mb-12">
                    <div class="container">
                        <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500">
                            PROBLEM STATEMENT
                        </h6>
                        <h3 class="text-2xl md:text-3xl font-extrabold mb-4 text-gray-700 dark:text-gray-200 ">Current issues with traditional cognitive assessment</h3>
                        <div class="flex flex-col rounded-xl p-8 mt-8 bg-stone-300/80 dark:bg-gray-800">
                            <ul class="p-0 list-none">
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Cognitive assessment are boring and unengaging.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Digitizing cognitive assessment and allowing people to test for potential cognitive disorder.</li>
                                <li class="border-b border-solid border-slate-500 pb-2 text-lg font-medium md:text-base text-gray-800 dark:text-gray-200 leading-relaxed my-4">Existing research points to Game based cognitive assessment and measure cognitive levels through reaction speed, memory based games.</li>
                            </ul>    
                        </div>
                    </div>
                </div>

                {/* Solution */}
                <div class="block relative mt-12 mb-12">
                    <div class="container">
                        <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500">
                            SOLUTION
                        </h6>
                        <h3 class="text-2xl md:text-3xl font-extrabold  text-gray-700 dark:text-gray-200 ">Whack-a-mole as a dynamic board game</h3>
                        <h4 class="text-xl md:text-2xl font-semibold mb-2 mt-4  text-gray-600 dark:text-gray-400 ">
                            The game features three levels of pattern recognition, created by the LED and parallel processing of the heart rate during gameplay.
                        </h4>
                        <img src={require(`../Images/whackamole/whackamole.jpeg`)}  alt="Game board" class="rounded-xl"/>   
                    </div>
                </div>

                {/* Further Details */}
                <div class="block relative mt-12 mb-12">
                    <div class="container">
                        <h6 class="font-mono text-lg md:text-base font-semibold mb-2 my-4 tracking-widest text-indigo-500">
                            FURTHER DETAILS
                        </h6>
                        <h3 class="text-2xl md:text-3xl font-extrabold mb-2 text-gray-700 dark:text-gray-200 ">Whack-a-mole as a dynamic board game</h3>
                        <h4 class="text-xl md:text-2xl font-semibold mb-2  text-gray-600 dark:text-gray-400 ">
                            The game features three levels of pattern recognition, created by the LED and parallel processing of the heart rate during gameplay.
                        </h4>
                        <p class="text-lg  md:text-base  text-gray-600 mb-4  dark:text-gray-400">The underlying hardware is a simple LED grid, whereas a lot more goes into the ML pipeline for the game to function well. This is a POC and I would like to tell more stories that underwent from research to gameboard and its limitations.</p>
                        <p class="text-lg  md:text-base  text-gray-600  dark:text-gray-400" >Let's connect or drop me a mail at abhi.saraf25@gmail.com</p>
                    </div>
                </div>

            </div>
        )
    }


    export default WackAMole;