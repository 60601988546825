import React from "react";
import "./components.css";
import arrowUp from './Images/arrow-up.png';
import { ArrowUp } from "lucide-react";


const ScrollToTopButton = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    
    return (
        <button className="rounded-button scroll-button"  onClick={ScrollToTopButton}>
            {/* <img className="scale50" src={arrowUp} alt="previous"/> */}
            <ArrowUp className="w-6 h-6 text-gray-600 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"/>
        </button>
    );

};
    
    

export default ScrollToTopButton;
