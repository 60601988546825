import PropTypes from "prop-types";
import "./components.css";
import lock from './Images/lock.png';

//Props for button, size, color, shape, secure case study 
const Button =({
    label, size="lg", 
    onClick, 
    fontWeight="font-regular", 
    color="primary",
    className,
    secure
}) =>{

//Button color classes
const colorClass = {
    primary: "bg-blue-600 hover:bg-blue-700 text-white",
    secondary: "bg-gray-800 hover:bg-gray-900 text-white",
    //Tertiary buttons - phase 2 (tailwind)
}
//Button size classes
const sizeClasses = {
    sm: "px-1.5 py-0.5 h-6 text-[10px] md:px-4 md:py-2 md:h-10 md:text-sm",
    md: "px-2 py-1 h-8 text-xs md:px-6 md:py-2 md:h-12 md:text-base",
    lg: "px-3 py-1 h-9 text-xs md:px-8 md:py-2 md:h-14 md:text-base"
};

const btnClass=`
    ${sizeClasses[size]}
    ${colorClass[color]}
    ${fontWeight}
    rounded
    md:rounded-md
    inline-flex 
    items-center 
    justify-center 
    transition-colors
    duration-200
    ease-in-out
    focus:outline-none
    focus:ring-1
    md:focus:ring-2
    focus:ring-offset-1
    md:focus:ring-offset-2
    focus:ring-${color}-500
    ${className}
`;

return(
    <button className={btnClass} onClick={onClick}>
        {secure && <img src={lock} className="h-3 md:h-5 ml-0 mr-1 md:mr-2" alt="lock"/> }
        {secure && <span className="mr-1 md:mr-4"></span>} {label}
    </button>
);
}


Button.propTypes = {
    label: PropTypes.string,
    size: PropTypes.oneOf(["sm","md","lg"]),
    fontWeight: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    icon: PropTypes.element
}

 export default Button;