import React from 'react';
import { motion } from 'framer-motion';

const BlogLayout = ({ children}) => {
    return (
        <div className={`min-h-screen `}>
            <div className=" min-h-screen transition-colors duration-500">
                <motion.div 
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="container mx-auto px-4 sm:px-6 lg:px-8"
                >
                    <div className="py-8">
                        {children}
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default BlogLayout;
