import React, { useState, useEffect } from 'react';
import BlogCard from '../Blog/BlogCard';
import { loadAllBlogs } from '../../utils/blogUtils';

const WritingsPage = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                console.log('Starting to fetch blogs...');
                const posts = await loadAllBlogs();
                console.log('Fetched posts:', posts);
                if (!posts || posts.length === 0) {
                    setError('No blog posts found');
                    return;
                }
                setBlogs(posts);
            } catch (error) {
                console.error('Error loading blogs:', error);
                setError('Failed to load blog posts');
            } finally {
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    if (loading) {
        return (
            <div className="text-center py-12">
                <h2 className="text-2xl font-mono text-gray-800 dark:text-white">Loading...</h2>
            </div>
        );
    }

    if (error) {
        return (
            <div className="text-center py-12">
                <h2 className="text-2xl font-mono text-gray-800 dark:text-white">{error}</h2>
                <p className="text-gray-600 dark:text-gray-400 mt-4">
                    Check console for more details
                </p>
            </div>
        );
    }

    return (
        <>
           <div className='block mt-24 mb-24'>
            <div className='container mx-auto px-4 sm:px-6 lg:px-8'>
            <h1 className="text-3xl font-bold mb-12 text-gray-800 dark:text-white">
                Writings
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 ">
                {blogs && blogs.map((blog) => (
                    <BlogCard
                        key={blog.id}
                        {...blog}
                    />
                ))}
            </div>
            </div>
           </div>
        </>
    );
};

export default WritingsPage;
