import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Badge from "./Badge/Badge";
import optumLogo from './Images/optum-logo-white.png';
import uwLogo from './Images/uw-logo-white.png';
import tcsLogo from './Images/tcs-logo-white.png';
import { FileText, Link2, Code } from 'lucide-react';
import { LayoutGrid, Building2, GraduationCap, GalleryVertical, MousePointerClick, Webhook, Origami, Blocks, SquareDashedBottomCode, CodeXml, ArrowUpRight } from 'lucide-react';
import { SiReact } from "react-icons/si";
import { RiTailwindCssFill } from "react-icons/ri";
import { FiFramer } from "react-icons/fi";
import tilebackdrop from './Images/backdrop.jpeg';
import { loadAllBlogs } from '../utils/blogUtils';

const techStackIcons = [
    { icon: SiReact, title: 'React icon' },
    { icon: FiFramer, title: 'Framer motion icon' },
    { icon: RiTailwindCssFill, title: 'Tailwind CSS' },
];

const skills = [
    { icon: MousePointerClick, title: 'Human-Computer Interaction' },
    { icon: Webhook, title: 'Systems Thinking' },
    { icon: Origami, title: 'Interaction Design' },
    { icon: Blocks, title: 'Design Systems' },
    { icon: SquareDashedBottomCode, title: 'Developer Collaboration' },
    { icon: CodeXml, title: 'Technologist' },
];

const fadeInAnimationVariants = {
    initial: {
        opacity: 0,
        y: 100,
    },
    animate: (index) => ({
        opacity: 1,
        y: 0,
        transition: {
            delay: 0.05 * index,
            duration: 0.5,
            ease: "easeInOut"
        },
    }),
};

const containerVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.3,
            staggerChildren: 0.2,
        },
    },
};

const TileLayout = ({ onWorkClick }) => {
    const [topBlogs, setTopBlogs] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchTopBlogs = async () => {
            const posts = await loadAllBlogs();
            if (posts && posts.length > 0) {
                const topBlogs = posts.slice(0, 3).map(({ id, title, date }) => ({ id, title, date }));
                setTopBlogs(topBlogs);
            }
        };

        fetchTopBlogs();
    }, []);

    const MobileLayout = () => (
        <div className="flex flex-col gap-2 px-2 -mt-16">
            {/* Profile Section */}
            <div className="w-full bg-stone-300 dark:bg-gray-800 rounded-xl p-4">
                <div className="flex items-center gap-4">
                    <img 
                        src={require(`./Images/profile-image.jpg`)} 
                        alt="" 
                        className="w-24 h-24 rounded-md"
                    />
                    <div>
                        <h1 className="text-2xl text-gray-800 dark:text-white">Hi, I'm Abhijeet</h1>
                        <p className="text-sm text-gray-600 dark:text-gray-300">Product Designer</p>
                    </div>
                </div>
                <p className="mt-4 text-gray-800 dark:text-white/90">
                    I infuse design into usable interfaces and believe that impactful design excels in the subtle interaction between people, culture, and technology.
                </p>
            </div>

            {/* Action Buttons */}
            <div className="grid grid-cols-2 gap-2">
                <a 
                    href="https://drive.google.com/file/d/1FeztXO-I19iHjuzyyO4OlnLcCAggGxTw/view?usp=drive_link" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="bg-gray-100 hover:bg-gray-200 rounded-xl p-4 flex items-center justify-between"
                >
                    <span className="font-mono font-semibold text-gray-600">RESUME</span>
                    <ArrowUpRight size={24} className="text-gray-600" />
                </a>
                <button 
                    onClick={onWorkClick}
                    className="bg-gray-100 hover:bg-gray-200 rounded-xl p-4 flex items-center justify-between"
                >
                    <span className="font-mono font-semibold text-gray-600">WORK</span>
                    <GalleryVertical size={24} className="text-gray-600" />
                </button>
            </div>

            {/* Components for Breakfast */}
            <div className="flex-1 h-full rounded-xl p-4 bg-gradient-to-br from-blue-600 to-blue-800 backdrop-blur-sm">
                <div className='flex items-center justify-between mb-4'>
                    <h6 className="font-mono text-sm font-semibold tracking-widest text-gray-100">
                        WRITINGS
                    </h6>
                    <a href="/writings" className="group">
                        <ArrowUpRight size={24} className='text-white group-hover:scale-110 transition duration-1000 ease-in-out'/>
                    </a>
                </div>
                <div className="flex flex-col gap-2">
                    {topBlogs.map((post, index, array) => (
                        <a key={index} href={`/writings/${post.id}`} className="flex flex-col">
                            <div className="flex items-center justify-between gap-2">
                                <span className="text-xs text-white/90 truncate max-w-[70%]">
                                    {post.title}
                                </span>
                                <span className='text-[10px] font-mono text-white/75 whitespace-nowrap'>
                                    {post.date}
                                </span>
                            </div>
                            {index !== array.length - 1 && (
                                <div className="border-b border-white/10 mt-2"></div>
                            )}
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );

    return (
        <motion.div 
            className="block relative drop-shadow-2xl"
            variants={fadeInAnimationVariants}
            initial="initial"
            whileInView="animate"
            viewport={{once: false}}
        >
            {isMobile ? (
                <MobileLayout />
            ) : (
                <div className="container">
                    <div className="w-full">
                        {/* First Row */}
                        <div className="flex mb-4 gap-4 h-48">
                            {/* Square Image Tile */}
                            <div className="w-48 h-full rounded-xl flex items-center justify-center">
                                <img src={require(`./Images/profile-image.jpg`)} alt="" className="rounded-xl"/>
                            </div>

                            {/* Header Text Tile */}
                            <div className="relative flex-grow h-full rounded-xl p-6 flex flex-col justify-center bg-stone-300 dark:bg-gray-800 ">
                                <h1 className="text-3xl mb-2 text-gray-800 dark:text-white">Hi, I'm Abhijeet</h1>
                                <p className="text-gray-800 dark:text-white/90 ">I infuse design into usable interfaces and believe that<br/>impactful design excels in the subtle interaction<br/>between people, culture, and technology.</p>
                            </div>

                            {/* Links Tile */}
                            <div className="w-48 h-full rounded-xl grid grid-rows-2 gap-4">
                                {/* Resume Section */}
                                <a href="https://drive.google.com/file/d/1FeztXO-I19iHjuzyyO4OlnLcCAggGxTw/view?usp=drive_link" target="_blank" rel="noopener noreferrer">
                                    <div className="bg-gray-100 hover:bg-gray-200 transition duration-1000 ease-in-out rounded-xl p-6 group">
                                        <div className="flex items-center justify-between">
                                            <h6 className="font-mono text-base font-semibold tracking-widest text-gray-600 group-hover:text-gray-800 transition duration-1000 ease-in-out">
                                                RESUME
                                            </h6>
                                            <ArrowUpRight size={32} className='text-gray-600 group-hover:text-gray-800 group-hover:scale-110 transition duration-1000 ease-in-out'/>
                                        </div> 
                                    </div>
                                </a>
                                {/* Work Section */}
                                <div 
                                    onClick={onWorkClick}
                                    className="bg-gray-100 hover:bg-gray-300 transition duration-1000 ease-in-out rounded-xl p-6 cursor-pointer group"
                                >
                                    <div className="flex items-center justify-between">
                                        <h6 className="font-mono text-base font-semibold tracking-widest text-gray-600">
                                            WORK
                                        </h6>
                                        <GalleryVertical size={32} className='text-gray-600 group-hover:scale-110 transition duration-1000 ease-in-out'/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Second Row */}
                        <div className="flex gap-4 h-48">
                            {/* Brand Images Tile */}
                            <div className="flex-1 h-full rounded-xl p-6 bg-gradient-to-br from-emerald-600 to-emerald-800 group hover:from-emerald-700 hover:to-emerald-900 transition duration-1000 ease-in-out  backdrop-blur-sm relative">
                                <div className="absolute inset-0 bg-cover bg-center rounded-xl" style={{ backgroundImage: `url(${require('./Images/bg-components-tile.png')})` }}></div>
                                <a href="/" className='flex-1 flex flex-col h-full justify-between relative z-10'>
                                    <div className='flex justify-between'>
                                        <div className='flex-1'>
                                            <h6 className="font-mono text-sm md:text-base font-semibold mb-2 tracking-widest text-gray-100">
                                                COMPONENTS for
                                            </h6>
                                            <h1 className="text-3xl font-light italic -mt-2 text-white">Breakfast</h1>
                                        </div>
                                        <ArrowUpRight size={32} className='text-white group-hover:scale-110 transition duration-1000 ease-in-out'/>
                                    </div>
                                    <h6 className="font-mono text-xs md:text-xs font-semibold tracking-widest leading-tight -mb-1 text-gray-100">
                                        DESIGN SYSTEMS • VISUAL DESIGN | <span className='italic bg-emerald-800 p-1 rounded-md'>COMING SOON</span>
                                    </h6>
                                </a>
                            </div>

                            {/* Blog Posts Tile */}
                            <div className="flex-1 h-full rounded-xl p-6 bg-gradient-to-br from-blue-600 to-blue-800 backdrop-blur-sm">
                                <div className='flex items-center justify-between'>
                                    <h6 className="font-mono text-sm md:text-base font-semibold tracking-widest text-gray-100">
                                        WRITINGS
                                    </h6>
                                    <a href="/writings" className="group">
                                        <ArrowUpRight size={32} className='text-white group-hover:scale-110 transition duration-1000 ease-in-out'/>
                                    </a>
                                </div>
                                <div className="flex flex-col justify-between h-[calc(100%-6rem)]">
                                    {topBlogs.map((post, index, array) => (
                                        <a key={index} href={`/writings/${post.id}`} className="flex flex-col">
                                            <div className="flex items-center gap-1 justify-between text-white py-2">
                                                <span className="text-sm text-white/90 truncate">{post.title}</span>
                                                <span className='text-xs font-mono'>{post.date}</span>
                                            </div>
                                            {index !== array.length - 1 && (
                                                <div className="border-b border-white/10 "></div>
                                            )}
                                        </a>
                                    ))}
                                </div>
                            </div>

                            {/* Skills Tile */}
                            <div className="flex-1 h-full rounded-xl p-6 bg-stone-300 dark:bg-gray-800 overflow-hidden ">
                                <h6 className="font-mono text-sm md:text-base font-semibold mb-2 tracking-widest text-gray-800 dark:text-white">
                                    SKILLS
                                </h6>
                                <div className="flex flex-wrap gap-1.5">
                                    {skills.map(({ icon: Icon, title }, index) => (
                                        <div 
                                            className="p-1.5 border rounded-md text-nowrap border-white/20 bg-black/20"
                                            key={index}
                                        >
                                            <div className="flex items-center">
                                                <Icon size={14} className="text-white" style={{ marginRight: '6px' }} />
                                                <span className="text-xs text-white">{title}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </motion.div>
    );
};

export default TileLayout;