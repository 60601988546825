import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {Link} from 'react-router-dom';
import Badge from '../Badge/Badge';
import Button from '../Button';
import "./Footer.css";
import "../components.css";
import emailIcon from '../Images/email.png';
import githubIcon from '../Images/github.png';
import linkedinIcon from '../Images/linkedin.png';
import logoImage from '../Images/140x140.png';

const Footer = () => {
    const[showModal, setShowModal] = useState(false);
    const[password, setPassword] = useState("");
    const[error, setError] =useState("");
    const navigate = useNavigate();
    //Hardcoded password
    const correctPassword = "@Recruiter2024";

    const openModal = () => {
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
        setError("");
        setPassword("");
    }

    const handleSubmit = () => {
        if(password === correctPassword){
            //Proceed with accessing new project
            //For now, let's just close the modal
            navigate(`project/optum`);
            closeModal();
        } else {
            setError("Please enter correct password");
        }
    };

    return(
        <div>
            <hr class=" border-slate-500 dark:border-slate-800 mb-8"/>
            <div class='container'>
                <div class=''>
                    {/* Logo and Project Row */}
                    <div>
                        <div className='flex mt-4 mb-4 justify-between flex-col md:flex-row items-center'>
                            <div className='mb-8 md:mb-0'>
                                <a href="/" aria-current="page" className=" justify-center" aria-label="home">
                                  <div className='flex flex-col items-center'>
                                    <div className='font-bakbak text-5xl text-gray-600 dark:text-gray-200 '>अ</div>
                                    <h5 class="text-lg md:text-xl font-bold text-gray-600 dark:text-gray-200 leading-snug mt-2 mb-4">Abhijeet Saraf</h5>
                                  </div>
                                </a>
                            </div>
                            <ul className='list-unstyled mb-8 md:mb-0'>
                                    <h6 class="text-lg md:text-base font-mono text-stone-700 dark:text-gray-200 leading-relaxed mb-2">PROFESSIONAL & ACADEMIC WORK</h6>
                                    <li><Link class="text-gray-500 hover:text-gray-900 dark:text-white/50 transition-colors duration-300 ease-in-out dark:hover:text-white" to="/project/previsit">Optum Pre-Visit Assessment</Link></li>
                                    <li><Link class="text-gray-500 hover:text-gray-900 dark:text-white/50 transition-colors duration-300 ease-in-out dark:hover:text-white" to="/project/optum">Optum HouseCalls</Link></li>
                                    <li><Link class="text-gray-500 hover:text-gray-900 dark:text-white/50 transition-colors duration-300 ease-in-out dark:hover:text-white" to="/project/smartsales">SmartSales Opportunity</Link></li>
                                    <li><Link class="text-gray-500 hover:text-gray-900 dark:text-white/50 transition-colors duration-300 ease-in-out dark:hover:text-white" to="/project/paceport">PacePort Digital Library</Link></li>
                                    <li><Link class="text-gray-500 hover:text-gray-900 dark:text-white/50 transition-colors duration-300 ease-in-out dark:hover:text-white" to="/project/marysplace">Mary's Place, Seattle</Link></li>
                                    <li><Link class="text-gray-500 hover:text-gray-900 dark:text-white/50 transition-colors duration-300 ease-in-out dark:hover:text-white" to="/project/vocalamp">Vocalamp</Link></li>
                                    <li><Link class="text-gray-500 hover:text-gray-900 dark:text-white/50 transition-colors duration-300 ease-in-out dark:hover:text-white" to="/project/whackamole">Whack-a-mole</Link></li>
                            </ul>
                            <div className='mb-8 md:mb-0'>
                                   
                                    {[
                                        ["mailto:abhi.saraf25@gmail.com",emailIcon],
                                        ["https://github.com/abhijeetsaraf",githubIcon],
                                        ["https://www.linkedin.com/in/abhijeet-saraf/",linkedinIcon],
                                        ].map(([link,icon])=>(
                                            <a target='blank' aria-current="page" href={link}>
                                            <img class="opacity-50 mr-6 transition-opacity duration-300 ease-in-out hover:opacity-100 [filter:brightness(0)_saturate(0)_opacity(0.6)] dark:brightness-0 dark:invert" width="auto" height="auto" alt="web-logo" src={icon}/>
                                            </a>
                                    ))}
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
            <hr class="border-stone-300 dark:border-slate-900 mb-6"/>
            
            <div class="container font-mono font-bold text-stone-700 dark:text-gray-200">&copy; Copyright | Abhijeet Saraf 2025</div>
            {/* Modal Content  */}
            { showModal &&
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <h2>Password protected</h2>
                        <p>This project is password protected, please enter password</p>
                        
                        <div>
                            <div className="input-button-row">
                                <input type="password" text="password" value={password} onChange={(e)=> setPassword(e.target.value)} placeholder="Enter password" />
                                <Button size="md" label="Submit" onClick={handleSubmit} className="primary-4"/>    
                            </div>
                        </div>
                        {/* <button onClick={handleSubmit}>Submit</button> */}
                        {error && <p className="error-message">{error}</p>}
                    </div>
                </div>
            }
        </div>
    )
}

export default Footer;