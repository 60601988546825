import React, { useRef } from "react";
import TileLayout from "../Tilelayout";
import ProjectSection from "./ProjectSection";

const Work = () => {
    const projectRef = useRef(null);

    const handleWorkClick = () => {
        projectRef.current?.scrollIntoView({ 
            behavior: 'smooth',
            block: 'start'
        });
    };

    return (
        <div>
            <div className="mt-32 mb-0 md:mb-32">
                <TileLayout onWorkClick={handleWorkClick} />
            </div>
            
            <div ref={projectRef} className="min-h-screen">
                <ProjectSection />
            </div>
        </div>
    );
};

export default Work;